<template>
    <v-card flat>
      <v-toolbar flat color="info">
            <v-toolbar-title>
                Admin Toolbox
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-alert color="secondary" outlined icon="fas fa-exclamation-triangle" dense>
                        There are currently {{getIssues}} issues submitted for review.
                    </v-alert>
                </v-col>
                <v-col cols="12">
                    <v-alert color="secondary" outlined icon="fas fa-hourglass-start" dense>
                        There are currently {{getThemes}} themes pending review.
                    </v-alert>
                </v-col>
            </v-row>
            <v-row align="center" justify="space-around">
                <v-card outlined height="175" width="175" v-for="item in getAdminNav" :key="item.title" :to="item.link" class="ma-3">
                    <v-container fill-height>   
                        <v-row no-gutters>
                            <v-col cols="12" class="text-center">
                                <v-icon>{{item.icon}}</v-icon> 
                                <p class="subtitle-1">
                                    {{item.title}}
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    created(){
        this.$store.dispatch('getUserList')
    },
    computed: {
        getAdminNav(){
            return this.$store.getters.getAdminNav
        },
        getIssues(){
            return this.$store.getters.getIssues.filter(issue => issue.active == true).length
        },
        getThemes(){
            return this.$store.getters.getThemes.filter(theme => theme.approved == 'pending').length
        }
    }
}
</script>